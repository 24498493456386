.waitlist-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

/* Mobile-specific styles */
.waitlist-container-mobile {
  max-width: 90%; /* Adjust width for mobile */
  padding: 10px;
  margin: 0 auto;
}

.waitlist-container {
  overflow: visible;
}

/* .scroll-arrow {
  position: absolute;
  bottom: 10px; /* Adjust to fit above padding */
  /* left: 50%;
  transform: translateX(-50%);
  padding-bottom: 20px;
  animation: bounce 2s infinite;
} 

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #6b62ff; 
} */

@keyframes bounce {
  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

html,
body {
  height: 100%;
  margin: 0;
  /* overflow-y: scroll;
  overflow-x: hidden; */ 
  margin: 0;
  padding: 0;
  width: 100%; /* Ensure full width */
  box-sizing: border-box;
}

.container-15 {
  padding-bottom: 80px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.container-16 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #6b62ff;
  min-height: 65vh;
  padding-bottom: 80px;
  padding-top: 40px;
  box-sizing: border-box;
  overflow: hidden; /* Prevent scrollbars here */
}
/* Container for centering both columns */

/* Ensure each column takes up half the width */
.additional-content {
  width: 65%; /* Each column takes up half the width */
  display: flex; /* Enables Flexbox within columns */
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Horizontally center content */
  justify-content: center; /* Vertically center content */
  text-align: center; /* Center text alignment */
}

.waitlist-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
  overflow: hidden;
}

.toast-message {
  background: #6b62ff;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.waitlist-header {
  text-align: left;
  color: var(--primary-text);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 20px;
}

.name-row {
  display: flex; /* Enables Flexbox for First and Last Name */
  gap: 10px; /* Adds spacing between inputs */
  margin-bottom: 10px; /* Adds spacing between rows */
}

.form-input {
  flex: 1; /* Ensures inputs share available space */
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.image-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.image-row-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.image-row-mobile .side-by-side-image {
  max-width: 90%;
  /* height: auto; */
  width: 90%;
  margin-bottom: 20px; /* Add spacing between images */
}

.side-by-side-image {
  /* width: 55%; Adjust the width to fit the layout */
  max-width: 350px; /* Optional: Limit the max width */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for better visuals */
}

/* Desktop styles */
.team-up {

}

/* Mobile styles */
.team-up-mobile {
  text-align: center;
  font-size: 2.5rem; /* Make the text smaller on mobile */
  font-weight: 800;
  color: white;
  margin: 10px 0; /* Reduce margins for smaller screens */
}

.email-input {
  width: 100%; /* Email input spans full width */
}

.submit-button {
  background: #6b62ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background: #5a53e0;
}

.form-input:focus {
  outline: none;
  border-color: var(--cta-bg);
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: var(--accent);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: var(--accent-hover);
}

.verify-form {
  text-align: center;
}
.resend-button {
  background-color: #e2e2e2;
  color: #333;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 8px;
}

.resend-button:hover {
  background-color: #ccc;
}

.resend-button:active {
  background-color: #bbb;
  transform: scale(0.98); /* Adds a slight press effect */
}

.resend-button:disabled {
  background-color: #f0f0f0;
  color: #aaa;
  cursor: not-allowed;
}

.signin-text {
  font-size: 0.9rem;
  color: #333;
  text-align: center;
  margin-top: 10px;
}

.signin-link {
  color: var(--accent);
  text-decoration: none;
}

.signin-link:hover {
  text-decoration: underline;
}

/* Hero Section */
.home__hero-waitlist {
  justify-content: center; /* Center items vertically */
  text-align: center; /* Align text to the left */
  left: 0;
  margin-top: 10px;
}

/* How It Works Section */
.how-it-works {
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9fb;
}

.how-it-works h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.how-it-works-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.how-it-works-step {
  flex: 1;
  text-align: center;
  margin: 10px;
  color: white;
}

.how-it-works-step img {
  /* max-width: 100%; */
  border-radius: 8px;
  /* margin-bottom: 10px; */
}

.how-it-works-step h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: white;
  text-align: center;
}

/* Mission Statement Section */
.mission-statement {
  padding: 40px 20px;
  background-color: #eaf1ff;
  text-align: center;
}

.mission-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mission-content img {
  max-width: 80%;
  border-radius: 8px;
}

/* Spotlight Section */
.spotlight-content img {
  width: 30%;
  max-width: 80px;
  border-radius: 8px;
}
.spotlight-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #f7f7f9;
}

.spotlight-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.spotlight-content img {
  max-width: 80%;
  border-radius: 8px;
}

/* Alpha Access Section */
.alpha-access {
  padding: 30px 20px;
  text-align: center;
  background-color: #6b62ff;
  color: #fff;
  padding-bottom: 50px;
}

.alpha-access p,
.spotlight-content p {
  margin: 0 auto; /* Center text */
  padding: 0 20px; /* Add space on smaller screens */
  max-width: 100%; /* Ensure no overflow */
  box-sizing: border-box;
  word-break: break-word; /* Prevent long words from breaking layout */
}

.alpha-access p {
  padding-bottom: 10px;
  font-weight: 600;
}

.alpha-access h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.alpha-access-button {
  padding: 12px 20px;
  font-size: 1rem;
  color: #6b62ff;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.alpha-access-button:hover {
  background-color: #eaeaea;
}
