/* Signup container */
.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 10px;
    background-color: #f7f7f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    margin-bottom: 50px;
  }
  .container-14 {
    padding-top:80px;
  }
  
  .scroll-arrow {
    position: relative;
    padding-left: 25px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #6B62FF; /* Change color as needed */
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateX(-50%) translateY(0);
    }
    50% {
      transform: translateX(-50%) translateY(10px);
    }
  }

  .signup-header {
    text-align: center;
    color: var(--primary-text);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  /* Form group */
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
  }
  
  .form-input:focus {
    outline: none;
    border-color: var(--cta-bg);
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: var(--accent);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: var(--accent-hover);
  }
  
  .verify-form {
    text-align: center;
  }
  .resend-button {
    background-color: #e2e2e2;
    color: #333;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 8px;
  }
  
  .resend-button:hover {
    background-color: #ccc;
  }
  
  .resend-button:active {
    background-color: #bbb;
    transform: scale(0.98); /* Adds a slight press effect */
  }
  
  .resend-button:disabled {
    background-color: #f0f0f0;
    color: #aaa;
    cursor: not-allowed;
  }
  
  .signin-text {
    font-size: 0.9rem;
    color: #333;
    text-align: center;
    margin-top: 10px;
  }
  
  .signin-link {
    color: var(--accent);
    text-decoration: none;
  }
  
  .signin-link:hover {
    text-decoration: underline;
  }

  /* Hero Section */
.home__hero-waitlist {
  justify-content: center; /* Center items vertically */
  text-align: center; /* Align text to the left */
  left: 0;
  margin-top: 10px;
  position: relative;
}

.team-up-mobile {
  text-align: center;
  font-size: 2.5rem; /* Make the text smaller on mobile */
  font-weight: 800;
  color: white;
  margin: 10px 0; /* Reduce margins for smaller screens */
}

.team-up {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  margin: 20px 0;
}

/* How It Works Section */
.how-it-works {
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9fb;
}

.how-it-works h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.how-it-works-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.how-it-works-step {
  flex: 1;
  text-align: center;
  margin: 10px;
  color: white;
}

.how-it-works-step img {
  /* max-width: 100%; */
  border-radius: 8px;
  /* margin-bottom: 10px; */
}

.how-it-works-step h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
}

/* Mission Statement Section */
.mission-statement {
  padding: 40px 20px;
  background-color: #eaf1ff;
  text-align: center;
}

.mission-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mission-content img {
  max-width: 80%;
  border-radius: 8px;
}

/* Spotlight Section */
.spotlight-content img {
  width: 30%;
  max-width: 80px;
  border-radius: 8px;
}
.spotlight-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #f7f7f9;
}

.spotlight-section h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  padding-bottom: 20px;
}

.spotlight-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.spotlight-content img {
  max-width: 80%;
  border-radius: 8px;
}

/* Alpha Access Section */
.alpha-access {
  padding: 30px 20px;
  text-align: center;
  background-color: #6b62ff;
  color: #fff;
  padding-bottom: 50px;
}

.alpha-access p,
.spotlight-content p {
  margin: 0 auto; /* Center text */
  padding: 0 20px; /* Add space on smaller screens */
  max-width: 100%; /* Ensure no overflow */
  box-sizing: border-box;
  word-break: break-word; /* Prevent long words from breaking layout */
}

.alpha-access p {
  padding-bottom: 10px;
  font-weight: 300 !important;
}

.alpha-access h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.alpha-access-button {
  padding: 12px 20px;
  font-size: 1rem;
  color: #6b62ff;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.alpha-access-button:hover {
  background-color: #eaeaea;
}
  
  