/* General header styles */
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent; /* Default transparent for logged-out users */
  box-shadow: none; /* No shadow by default */
  transition: background-color 0.4s ease, box-shadow 0.4s ease;
}

.header__profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  cursor: pointer;
}

.header__profile-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header__profile-icon svg {
  color: #aaa;
}


/* White background for logged-in users */
.header.logged-in {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* White background on scroll for logged-out users */
.header.scrolled {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Brand/Logo Styling */
.header__logo-image {
  height: 40px;
  /* padding-left:70px; */
}

/* Navigation Links */
.header__nav {
  display: flex;
  gap: 15px;
  padding-right:50px;
}

.header__link {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  transition: color 0.3s ease;
}

/* Profile Dropdown */
.header__profile-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.header__logo-image {
  height: 40px; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
  display: block; /* Ensures no inline spacing */
}

/* Brand/Logo styling */
.header__brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.header__logo {
  color: var(--primary-text); /* Matches header text color */
  text-decoration: none;
}

/* Upgrade CTA styling */
.header__upgrade-cta {
  background-color: var(--accent); /* Highlight color for the CTA button */
  color: var(--cta-text); /* Text color contrasts with button background */
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
}

.header__upgrade-cta:hover {
  background-color: var(--accent-hover); /* Dynamic hover color */
}

/* Navigation links styling */
.header__nav {
  display: flex;
  align-items: center;
  gap: 15px;
}

.header__link {
  color: black; /* Matches header text color */
  text-decoration: none;
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header__link:hover {
  background-color: var(--button-hover-bg); /* Hover effect */
}

/* Profile Icon and Dropdown styling */
.header__profile {
  position: relative;
  cursor: pointer;
}

.header__profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid var(--cta-text); /* Matches header text color */
  transition: border-color 0.3s ease;
}

.header__profile-icon:hover {
  border-color: var(--highlight); /* Highlight color for hover */
}

/* Profile Dropdown Animation */
.header__profile-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: var(--chat); /* Matches secondary background color */
  color: var(--primary-text); /* Matches primary text color */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  width: 150px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  visibility: hidden;
  z-index: 1;
}

.header__profile-dropdown.open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

/* Dropdown Item Styling */
.header__dropdown-item {
  padding: 10px 15px;
  text-decoration: none;
  color: var(--primary-text); /* Matches primary text color */
  font-size: 0.9rem;
  background-color: white ;/* Consistent starting style */
  transition: background-color 0.2s ease, color 0.2s ease;
}

.header__dropdown-item:hover {
  background-color: var(--button-hover-bg);/* Matches secondary background color */
  color: var(--primary-text); /* Slightly brighter text for hover */
}

/* Consistent Rounded Edges on Hover */
 .header__profile-dropdown .header__dropdown-item:first-child:hover {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.header__profile-dropdown .header__dropdown-item:last-child:hover {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Consistent Rounded Edges on Hover */
.header__profile-dropdown .header__dropdown-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.header__profile-dropdown .header__dropdown-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
