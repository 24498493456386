/* Main Blog page styling */
.blog {
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .blog h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Grid Layout */
  .blog__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  /* Individual Blog Tiles */
  .blog__tile {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: transform 0.2s ease;
    cursor: pointer;
  }
  
  .blog__tile:hover {
    transform: translateY(-5px);
  }
  
  .blog__image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .blog__title {
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
  }
  