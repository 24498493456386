/* Main container */
.edit-profile {
  padding: 20px;
  background: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.edit-profile h1 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Horizontal Tab Menu */
.tab-menu {
  display: flex;
  justify-content: left;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.tab-button {
  font-family: Montserrat;
  padding: 10px 15px;
  background-color: #f1f1f1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.tab-button.active {
  background-color: var(--cta-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: default;
}

.tab-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Tab Content Area */
.tab-content {
  background-color: #fff;
  min-height: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-name {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 10px;
}

/* Connections Section */
.connections-section {
  display: flex;
  flex-wrap: wrap; /* Ensures connections wrap to a new line */
  gap: 12px; /* Adds space between connection items */
  padding: 16px;
  background: white; /* Or your preferred background color */
  max-width: 100%; /* Ensures it doesn't overflow */
  min-height: 100px; /* Prevents it from collapsing when empty */
  justify-content: left; /* Center content inside */
  align-items: flex-start;
}

.connection-icon {
  width: 60px; /* Adjust as needed */
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.no-connections-text {
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: gray;
}

/* Details Section */
.details-section label {
  font-weight: bold;
  display: block;
  margin: 5px 0 5px;
}

.details-section input,
.details-section textarea,
.details-section select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

/* Notifications Section */
.notifications-section label {
  display: block;
  /* margin: 10px 0; */
}

/* Billing Section */
.billing-section p {
  color: #666;
}

/* Delete Account Section */
.delete-account-section p {
  color: #900;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.delete-button {
  background-color: #d9534f;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Montserrat;
}

.delete-button:hover {
  background-color: #c9302c;
}

.profile-picture-container {
  position: relative;
  display: inline-block;
}

.upload-container {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

/* Override margin for spinner in EditProfile only */
.edit-profile .spinner-container.medium {
  margin: 20px !important; /* Force removal */
}

.upload-button {
  margin-top: 10px;
  width: 150px;
  padding: 8px 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Montserrat;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.upload-button:hover {
  background-color: #d32f2f;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #ccc;
  object-fit: cover;
}

.pencil-icon {
  position: absolute;
  bottom: 5px;
  left: 105px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pencil-icon:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Message Button Styling */
.message-button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: var(--cta-bg);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Montserrat;
}

.message-button:hover {
  background-color: var(--cta-bg-hover);
}

.message-button:active {
  background-color: var(--accent);
}

.message-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--accent);
}

/* Connection Icon Hover Effect */
.connection-icon {
  width: 60px; /* Adjust as needed */
  height: 60px; /* Adjust as needed */
  border-radius: 50%;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.connection-icon:hover {
  transform: scale(1.1); /* Slightly larger on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

/* Remove from Connections Button Styling */
.remove-button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #dc3545; /* Red background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Montserrat;
}

.remove-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

.remove-button:active {
  background-color: #bd2130; /* Even darker red when active */
}

.remove-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); /* Red focus ring */
}

.toggle-container {
  display: flex;
  flex-direction: column;
}

.toggle-switch {
  width: 40px;
  height: 20px;
  background-color: #ddd;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin-right: 15px;
}

.toggle-switch.active {
  background-color: var(--accent);
}

.toggle-knob {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.toggle-switch.active .toggle-knob {
  transform: translateX(20px);
}

label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  gap: 10px;
}

/* .notifications-section {
  padding: 20px;
} */

h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  text-align: left;
}
/* 
.toggle-label {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1rem;
  color: #333;
} */

.toggle-label {
  display: flex;
  align-items: center; /* Center content vertically */
  gap: 10px; /* Space between label and toggle switch */
  font-size: 16px; /* Ensure readable label size */
  color: var(--primary-text); /* Dynamic text color */
  padding: 10px 0; /* Optional: Add vertical padding */
}

.placeholder-text {
  color: #a9a9a9; /* Light grey color for placeholder */
  font-style: italic; /* Optional: Makes it look like a placeholder */
}

/* Wrapper for buttons */
.button-group {
  display: flex; /* Use flexbox to align buttons horizontally */
  gap: 10px; /* Add space between buttons */
  align-items: center; /* Vertically align buttons */
  margin-top: 20px;
}

/* Save Button */
.save-button {
  padding: 10px 15px;
  font-size: 1rem;
  background-color: var(--cta-bg);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Montserrat;
}

.save-button:hover {
  background-color: var(--cta-bg-hover);
}

/* Edit Toggle Button */
.edit-toggle-button {
  padding: 10px 15px;
  font-size: 1rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Style for "Edit" mode */
.edit-toggle-button.edit-mode {
  background-color: lightgrey;
  color: #fff;
}

.edit-toggle-button.edit-mode:hover {
  background-color: var(--cta-bg);
}

/* Style for "Cancel Edit" mode */
.edit-toggle-button.cancel-mode {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f44336; /* Red background */
  color: white;
}

.edit-toggle-button.cancel-mode:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* General input field styles */
input {
  font-family: inherit; /* Use the same font as the parent */
  font-size: 1rem; /* Same font size as non-edit mode */
  color: #333; /* Default text color */
  border: 1px solid #ccc; /* Consistent border */
  border-radius: 5px;
  padding: 8px;
  width: 100%; /* Ensure consistent width */
  box-sizing: border-box;
}

/* Placeholder styling for input fields */
input::placeholder {
  color: #a9a9a9; /* Light grey placeholder */
  font-style: italic; /* Match placeholder style with non-edit mode */
}

/* Span for non-edit mode */
span {
  font-family: inherit; /* Use the same font as the input */
  font-size: 1rem;
  color: #333; /* Match text color */
}

/* Placeholder text style for span */
.placeholder-text {
  color: #a9a9a9; /* Light grey for placeholder */
  font-style: italic; /* Italic style for placeholder */
}

/* General input and textarea styles */
input,
textarea {
  font-family: inherit; /* Use the same font as the parent */
  font-size: 1rem; /* Ensure consistent font size */
  color: #333; /* Default text color */
  border: 1px solid #ccc; /* Consistent border */
  border-radius: 5px; /* Rounded corners for both */
  padding: 8px;
  width: 100%; /* Ensure consistent width */
  box-sizing: border-box;
  resize: none; /* Prevent textarea resizing */
}

/* Placeholder styling for input and textarea */
input::placeholder,
textarea::placeholder {
  color: #a9a9a9; /* Light grey color for placeholder */
  font-style: italic; /* Italicize placeholder text */
}

/* Non-edit mode span for placeholder */
.placeholder-text {
  color: #a9a9a9; /* Same placeholder color as input/textarea */
  font-style: italic; /* Match placeholder style */
  font-size: 1rem; /* Ensure consistent font size */
}

.billing-portal-button {
  background-color: var(--cta-bg);
  color: #fff;
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.billing-portal-button:hover {
  background-color: var(--cta-bg-hover);
}

/*POPUP*/
/* Overlay Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it is above other content */
}

/* Popup Card Styles */
.popup-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%; /* Responsive design */
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left; /* Ensures content is left-aligned */
  overflow-y: auto; /* Allow scrolling if content overflows */
  max-height: 80vh; /* Prevent popup from exceeding viewport height */
}

/* Close Button */
.close-button {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  align-self: flex-end; /* Aligns button to the top-right */
  margin-bottom: 10px;
}

/* Popup Image */
.popup-card__image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
  object-fit: cover;
}

/* Popup Content */
.popup-card__name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup-card p {
  margin: 8px 0;
  line-height: 1.4;
  font-size: 0.95em;
}

/* Buttons */
.remove-button,
.message-button {
  background-color: var(--cta-bg);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  font-family: Montserrat;
}

.remove-button:hover {
  background-color: #d9534f;
}

.message-button:hover {
  background-color: #0056b3;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.bubbles-container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}

.bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #f1f1f1;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  transition: all 0.3s ease;
}

.bubble:hover {
  background-color: #e0e0e0;
}

.bubble .close-btn {
  background: none;
  border: none;
  color: #888;
  font-size: 1rem;
  cursor: pointer;
}

.bubble .close-btn:hover {
  color: #f44336;
}
.error-message {
  background: #ffe0e0;
  color: #b00020;
  padding: 10px 15px;
  margin: 15px 0;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  font-size: 0.95em;
  text-align: center;
}
