@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

/* General App Styles */
.App {
  text-align: left;
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  /* min-height: 50vh; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Upgrade Button */
.upgrade-cta {
  background-color: #ff9900;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-close {
  margin-top: 10px;
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
/* Main App Content: Allow the entire app to scroll */
.app-content {
  display: flex; 
  flex-direction: row; 
  height: auto;
  min-height: calc(
    100vh - 60px
  );
  overflow-y: auto;
  position: relative;
}

.full-page-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
}



/* Default Background for All Pages */
.main-content {
  flex: 1;
  overflow-y: visible;
  background-image: url("./imgs/gradient_hero.png");
  background-size: cover;
  background-position: center;
  transition: background 0.3s ease-in-out;
}

/* White Background Only for Edit Profile Page */
.edit-profile-bg .main-content {
  background-image: none;
  background-color: white;
}

/* Sidebar (if applicable): Adjust height to align with main content */
.sidebar {
  overflow-y: auto; /* Enable scrolling within the sidebar, if needed */
}

/* Footer: Ensure it stays at the bottom */
footer {
  margin-top: auto; /* Push the footer to the bottom of the page */
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
}

/* THEME */
/* .app-light {
  background-color: #0DA2FF;
  color: #000000;
}

.app-dark {
  background-color: #A4A4A6;
  color: #ffffff;
}  */
