/* General Styling */
.home__hero h1,
.home__hero p,
.home__hero .cta-upgrade-button {
  position: relative;
  z-index: 1;
  color: var(--primary-text);
}

/* Hero Section */
.home__hero {
  padding-left: 100px;
  height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end; /* Align items to the left */
  justify-content: center; /* Center items vertically */
  background: var(--primary-bg); /* Background color or image */
  color: var(--primary-text); /* Ensure text color matches the theme */
  text-align: center; /* Align text to the left */
}

.home__hero h1 {
  font-size: 5rem;
  margin-bottom: 20px;
}

.home__hero p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.home__hero .cta-upgrade-button {
  padding: 10px 20px;
  background-color: var(--cta-bg);
  color: var(--cta-text);
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

.home__cta .cta-upgrade-button {
  padding: 10px 20px;
  background-color: var(--cta-bg);
  color: var(--cta-text);
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

.home__cta .cta-upgrade-button:hover {
  background-color: var(--cta-bg-hover);
}

.featured__members .cta-upgrade-button:hover {
  background-color: var(--cta-bg-hover);
}

.featured__members .cta-upgrade-button {
  padding: 10px 20px;
  background-color: var(--cta-bg);
  color: var(--cta-text);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top:15px;
}

.home__hero .cta-upgrade-button:hover {
  background-color: var(--cta-bg-hover);
}




.home__random button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Shared Styles for Features Section */
.home__features {
  padding: 60px 20px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center; 
  text-align: center;
  padding-left:100px;
  margin-bottom: 40px; /* Ensure proper spacing after the section */
}

.home__features h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

.home__features-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.home__feature {
  flex: 1;
  max-width: 300px;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.home__feature h3 {
  font-size: 1.2rem;
}

.home__feature p {
  font-size: 1rem;
}

/* Shared Styles for Testimonials Section */
.home__random, .home__testimonials {
  padding: 60px 20px;
  text-align: center;
  margin-bottom: 40px; /* Ensure proper spacing after the section */
}

.home__random, .home__testimonials h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.home__random, .home__testimonials p {
  font-style: italic;
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Shared Styles for CTA Section */
.home__cta {
  padding: 60px 20px;
  text-align: center;
  margin-bottom: 40px; /* Ensure proper spacing after the section */
}

.home__cta h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.home__cta button {
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Add smooth transitions to all theme changes */
* {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Ensure consistent spacing between sections */
.home__hero,
.home__features,
.home__testimonials,
.home__random,
.home__cta {
  margin-bottom: 20px; /* Adds space between sections */
  border-radius: 12px; /* Rounds the edges */
  overflow: hidden; /* Ensures content respects the rounded edges */
}
.home__logos {
  padding-top: 100px;
  padding-left: 105px;
}

.logos__container {
  display: flex;
  justify-content: center; /* Center logos */
  align-items: center; /* Vertically align logos */
  flex-wrap: wrap; /* Allow wrapping if screen width is too small */
  gap: 50px; /* Space between logos */
  padding: 10px 20px;
}

.logo {
  height: 80px; /* Uniform height */
  width: auto; /* Maintain aspect ratio */
  filter: brightness(0); /* Convert to black */
  opacity: 0.8; /* Slight transparency for uniformity */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.logo:hover {
  opacity: 1; /* Full opacity on hover */
  transform: scale(1.1); /* Slight scale-up on hover */
}
/* Artist Spotlight Section */
.artist__spotlight {
  text-align: center;
  padding: 40px 20px;
}

.spotlight__title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.spotlight__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.spotlight__image {
  width: 80%; /* Responsive width */
  max-width: 600px; /* Limit maximum size */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  object-fit: cover; /* Ensure the image scales properly */
}

.spotlight__text {
  font-size: 1.2rem;
  color: #555;
  margin-top: 10px;
  max-width: 600px; /* Limit text width */
  line-height: 1.6; /* Better readability */
}

.spotlight__text .highlight {
  color: var(--accent); /* Accent color to match site theme */
  font-weight: bold; /* Highlight stylized words */
}
/* Featured Members Section */
.featured__members {
  text-align: center;
  padding: 40px 20px;
  /* background-color: #f9f9f9; Slight contrast from the rest of the page */
}

.featured__title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 50px;
}

.featured__content {
  display: flex;
  justify-content: center; /* Center the images */
  align-items: flex-start; /* Align text and images at the top */
  gap: 10px; /* Space between the images */
  flex-wrap: wrap; /* Wrap on smaller screens */
}

.featured__member {
  text-align: center;
  max-width: 30%; /* Ensure members take up equal space */
  flex-grow: 1; /* Make items flexible for responsive design */
  padding-bottom: 50px;
}

.featured__image {
  width: 100%; /* Ensure images scale responsively */
  max-width: 300px; /* Limit maximum size */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  object-fit: cover; /* Maintain image proportions */
}

.featured__name {
  font-size: 1.2rem;
  color: #555;
  margin-top: 10px;
  font-weight: bold;
}

/* Ensure uniform size for images */
.image-wrapper {
  width: 100%; /* Full width of the parent container */
  max-width: 300px; /* Optional: Limit the maximum size */
  /* height: 400px; */
  overflow: hidden; /* Hide any overflow from the image */
  border-radius: 10px; /* Optional: Rounded corners for consistency */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for design */
  margin: 0 auto; /* Center-align the wrapper */
  display: flex; /* Ensure proper centering of the image */
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; /* Optional: Placeholder background */
}

.featured__image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the wrapper while maintaining aspect ratio */
}

/* New Indie Releases Section */
.new-indie-releases {
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9; /* Match site style */
}

.section__title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 30px;
}

.releases__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 images per row */
  gap: 20px; /* Space between tiles */
  justify-content: center;
}

.release__tile {
  text-align: center;
}

.release__link {
  display: inline-block;
  text-decoration: none; /* Remove default link underline */
}

.release__image {
  width: 100%;
  max-width: 300px; /* Limit maximum size for consistency */
  aspect-ratio: 4 / 3; /* Uniform aspect ratio */
  object-fit: cover; /* Scale image without distortion */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.release__image:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); /* More prominent shadow */
}

.release__text {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #555;
  font-weight: bold;
}
/* Featured Videos Section */
.featured-videos {
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.featured-videos__header {
  margin-bottom: 30px;
}

.featured-videos__title {
  font-size: 2rem;
  color: #333;
}

.featured-videos__grid {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.featured-videos__item {
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin: 40px;
  overflow: hidden;
}

.featured-videos__iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.featured-videos__description {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #555;
  font-weight: bold;
}


