/* Spinner container */
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }
  
  /* Spinner animation */
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--accent);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  .spinner-container.small .spinner {
    width: 20px;
    height: 20px;
  }
  
  .spinner-container.large .spinner {
    width: 60px;
    height: 60px;
  }
  
  /* Spinner message */
  .spinner-message {
    margin-top: 10px;
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--accent);
  }
  
  /* Spinner animation keyframes */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  