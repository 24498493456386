/* Container Styles */
.container-12 {
  padding-top: 150px;
}

.signin-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  background-color: #f7f7f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

.signin-header {
  text-align: center;
  color: var(--primary-text);
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

/* Form Styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #6c63ff;
  box-shadow: 0 0 5px rgba(108, 99, 255, 0.5);
}

.error-message {
  color: #e63946;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 1rem;
  text-align: center;
}

/* Button Styles */
.submit-button, 
.back-button {
  width: 100%;
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background: #6c63ff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.submit-button:hover,
.back-button:hover {
  background: #5548c8;
}

.primary-button {
  background: #6c63ff;
  color: white;
}

.secondary-button {
  background: #f7f7f9;
  color: #6c63ff;
  border: 1px solid #6c63ff;
}

.secondary-button:hover {
  background: #e0e0ff;
}

/* Forgot Password Link Styled as a Small URL */
.forgot-password-text {
  font-size: 0.9rem;
  color: #333;
  text-align: center;
  margin-top: 10px;
}

.forgot-password-link {
  color: #007bff;
  font-size: 0.9rem;
  font-weight: normal;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}

.forgot-password-link:hover {
  color: #0056b3;
  text-decoration: none;
}

/* Text Links */
.signup-text {
  font-size: 0.9rem;
  color: #333;
  text-align: center;
  margin-top: 10px;
}

.signup-link {
  color: #007bff;
  text-decoration: none;
}

.signup-link:hover {
  text-decoration: underline;
}

/* Toast Notification Customization */
.Toastify__toast--success {
  background: white;
}

.Toastify__toast--error {
  background: #e63946 !important;
}
