/* Main container */
.find-team {
    padding: 20px;
    padding-left:100px;
  }
  
  .find-team h1 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    padding-top:150px; 
  }
  
  /* Filter button */
  .toggle-filters-button {
    display: block;
    margin: 10px auto 20px;
    padding: 10px 15px;
    background-color: var(--cta-bg);
    color: var(--cta-text);
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .toggle-filters-button:hover {
    background-color:var(--cta-bg-hover);
  }
  
/* Filters section */
.filters {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.filters label {
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}

.filters select {
  padding: 10px; /* Provide consistent padding */
  margin-left: 5px; /* Slight spacing between label and dropdown */
  border:none;
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Match text size */
  background-color: #fff; /* Ensure background color matches design */
  color: var(--primary-text);
  appearance: none; /* Remove default browser styling */
  outline: none; /* Remove outline on focus */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.filters select:focus {
  border-color: var(--button-hover-bg);
  box-shadow: 0 0 4px rgba(74, 144, 226, 0.5); /* Subtle shadow on focus */
}

.filters select:hover {
  border-color: var(--button-hover-bg)/* Highlight border on hover */
}
  
.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adapts to fit */
  justify-content: center;
  gap: 20px 40px;
  margin: 0 auto;
  padding: 0 20px;
}
  
  .find-team.chat-expanded .profile-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns when chat is expanded */
  }
  
  .profile-card {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s;
    width: 320px;
    height: 320px;
    box-sizing: border-box;
  }
  
  .profile-card:hover {
    transform: translateY(-5px);
  }
  
  .profile-card__name {
    font-size: 1.2rem;
    color: #333;
    margin: 5px 0;
    text-align: center;
  }
  
  .profile-card__specialty, .profile-card__location, .profile-card__goals {
    font-size: 0.9rem;
    color: #666;
    margin: 5px 0;
  }

  /* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it is above other content */
}

/* Popup Card */
.popup-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-y: auto;
  max-height: 80vh;
}

/* Close Button */
.close-button {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 10px;
  margin-top: 0px !important;
}

/* Profile Picture */
.profile-picture-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

/* Profile Content */
.popup-card__name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup-card p {
  margin: 8px 0;
  line-height: 1.4;
  font-size: 0.95em;
}
  
  .quick-view-button {
    margin-top: auto;
    padding: 8px 12px;
    font-size: 1em;
    font-weight: bold;
    background-color: var(--cta-bg);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    align-self: center;
  }
  
  .quick-view-button:hover {
    background-color: var(--cta-bg-hover);
  }
  
  .add-to-connections-button {
    margin-top: 15px;
    padding: 10px 15px;
    font-size: 1em;
    font-weight: bold;
    background-color:var(--cta-bg);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .add-to-connections-button:hover {
    background-color: var(--cta-bg-hover);
  }
  
  .add-to-connections-button:active {
    background-color: var(--cta-bg-hover);
  }
  
  .add-to-connections-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px car(--button-hover-bg);
  }

  .send-message-button {
    margin-top: 15px;
    padding: 10px 15px;
    font-size: 1em;
    font-weight: bold;
    background-color:var(--accent);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .send-message-button:hover {
    background-color: var(--accent-hover);
  }
  
  .send-message-button:active {
    background-color: var(--accent-hover);
  }
  
  .send-message-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px car(--button-hover-bg);
  }

  .profile-card__image-container {
    width: 100px; /* Ensure fixed width */
    height: 100px; /* Ensure fixed height */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  }
  .profile-card__image {
    max-width: 100%;  /* Adjust width automatically */
    max-height: 100%; /* Ensure full height */
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
  
  
  
.profile-card__image-container.connected {
  border: 3px solid var(--accent);
  padding: 2px;
  box-sizing: border-box;
}


  .loading-spinner {
    margin: 20px auto;
    text-align: center;
    font-size: 1.2rem;
    color: var(--primary-text);
  }
  
  .selected-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    padding: 10px; 
  }
  
/* Bubbles Container */
.bubbles-container-team {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 10px 0;
  padding-bottom:20px;
}

/* Individual Bubble */
.bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #f1f1f1;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  position: relative;
  transition: all 0.3s ease;
}

/* Hover Effect */
.bubble:hover {
  background-color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  cursor:default;
}

/* Close Button */
.bubble .close-btn {
  margin-left: 10px;
  background: none;
  border: none;
  color: #888;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.bubble .close-btn:hover {
  color: #f44336; /* Highlight the X on hover */
}

/* Color-Coded Categories */
.bubble.category-specialty {
  background-color: #d1e7ff;
  color: var(--primary-text);
}

.bubble.category-experience {
  background-color: #d6befe;
  color:var(--primary-text);
}

.bubble.category-location {
  background-color: var(--button-hover-bg);
  color:var(--primary-text);
}

.bubble.category-tools {
  background-color: #fff3cd;
  color: #856404;
}

/* Responsive Wrapping */
@media (max-width: 1024px) {
  .profile-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .profile-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .bubbles-container {
    justify-content: flex-start;
  }
}

/* Ensure blur only applies to the background */
.find-team.blurred {
  filter: blur(8px);
  pointer-events: none; 
  position: relative;
  z-index: 0;
}

/* Auth Popup Overlay */
.auth-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensures popup is above the blurred background */
}

/* Auth Popup Box */
.auth-popup {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  z-index: 1001; /* Higher than overlay */
  position: relative;
}

.auth-popup h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.auth-popup p {
  margin-bottom: 1.5rem;
}

.auth-popup-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.auth-button {
  padding: 0.75rem 2rem;
  background: var(--cta-bg);
  color: white;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.auth-button:hover {
  background: var(--cta-bg-hover);
}


  
  