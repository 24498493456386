/* Footer container */
.footer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
    font-family: Arial, sans-serif;
    
  }


  
  
  /* Company logo and name */
  .footer__brand {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .footer__logo {
    width: 50px;
    height: 50px;
  }
  
  .footer__company-name {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  /* Contact info */
  .footer__contact a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .footer__contact a:hover {
    color: #3498db;
  }
  
  /* Social media icons */
  .footer__social {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .footer__social a {
    color: #ecf0f1;
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .footer__social a:hover {
    color: #3498db;
  }
  
  /* Links section */
  .footer__links {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .footer__links a {
    color: #ecf0f1;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer__links a:hover {
    color: #3498db;
  }
  
  /* Copyright */
  .footer__copyright {
    font-size: 0.9rem;
    color: #bdc3c7;
    margin-top: 5px;
    text-align: right;
    width: 100%;
  }
  