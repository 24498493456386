/* Dropdown Container */
.dropdown-container {
    position: relative;
    display: inline-block;
    width: 150px;
    margin: 10px;
  }
  
  /* Selected Dropdown Area */
  .dropdown-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
  }
  
  /* Hover Effect */
  .dropdown-container:not(.active):hover .dropdown-select {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .dropdown-container.active .dropdown-select {
    box-shadow: none; /* Prevent shadow when dropdown is open */
  }
  
  .dropdown-select:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(209, 226, 245, 0.5);
  }
  
  /* Chevron Icon */
  .dropdown-select svg {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }
  
  .dropdown-container.active .dropdown-select svg {
    transform: rotate(180deg);
  }
  
  /* Dropdown Options */
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    max-height: 200px; /* Optional: limit dropdown height */
    overflow-y: auto;
  }
  
  /* Dropdown Option */
  .dropdown-option {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  .dropdown-option:hover {
    background-color: #f4f4f4;
  }
  
  .dropdown-option.active {
    background-color: var(--button-hover-bg);
    color: #fff;
  }
  
  /* Selected Filters (Bubbles) */
  .selected-bubbles {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
  }
  
  .filter-bubble {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 5px 10px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .filter-bubble:hover {
    background-color: #e8e8e8;
  }
  
  .filter-bubble .remove-bubble-button {
    background: none;
    border: none;
    font-size: 1rem;
    margin-left: 8px;
    color: #ff4d4d;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .filter-bubble .remove-bubble-button:hover {
    color: #ff0000;
  }
  