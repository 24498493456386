/* Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    max-width: 700px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .modal-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Subscription Tiles */
  .subscription-options {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;
    
  }
  
  .subscription-tile {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 200px;
    text-align: center;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .subscription-tile h3{
    text-align: center;
  }
  
  .popular-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ffcc00;
    color: #333;
    padding: 4px 8px;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 4px;
  }
  
  .subscription-tile h3 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .old-price {
    font-size: 0.9rem;
    color: #999;
  }
  
  .new-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .new-price span {
    font-size: 0.9rem;
    color: #666;
  }
  
  .billing-cycle {
    font-size: 0.9rem;
    color: #666;
    margin: 10px 0;
  }
  
  .upgrade-button {
    background-color: var(--accent);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .upgrade-button:hover {
    background-color: var(--accent-hover);
  }
  
  /* Close Button */
  .close-button {
    margin-top: 20px;
    background: none;
    border: none;
    color: #666;
    font-size: 1rem;
    cursor: pointer;
  }
  