.messaging-sidebar-container {
  display: flex;
  background-color: var(--secondary-bg);
  justify-content: flex-end;
  position: absolute;
  padding-top: 60px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Sidebar itself scrolls if needed */
}


/* Persistent Profile List */
.profile-list {
  width: 80px; /* Fixed width for profile images */
  background-color: var(--chat); /* Dynamic background */
  display: flex;
  padding-bottom: 60px;
  flex-direction: column;
  overflow-y: auto; /* Scrollable for overflow */
  border-right: 1px solid var(--secondary-bg); /* Dynamic border color */
  z-index: 10; /* Keep above other elements */
   /* Full height of the viewport */
  position: sticky; /* Ensure it stays in place when scrolling */
  top: 0; /* Align to the top of the viewport */
}

.profile-list img {
  width: 60px;
  height: 60px;
  border-radius: 50%; /* Round profile images */
  margin: 10px auto;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.profile-list img:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

@keyframes sideBarSlide {
  from {
    width: 0px;
  }
  to {
    width: 40vh;
  }
}
.expanded-sidebar {
  background-color: var(--chat);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjust height to avoid header */
  width: 40vh;
  position: fixed;
  top: 60px; /* Moves it below the header (adjust if necessary) */
  left: 80px; /* Aligns with the profile list */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Transitioning Behavior */
.collapsed {
  transform: translateX(-100%); /* Move sidebar out of view when collapsed */
  width: 80px; /* Small width for collapsed icons */
}

.open {
  transform: translateX(0); /* Bring sidebar into view */
}

/* Toggle Button for Sidebar */
.sidebar-toggle {
  position: absolute;
  right: -40px; /* Adjust position for left alignment */
  top: 70px;
  background-color: var(--highlight); /* Accent color */
  color: var(--chat); /* Contrasting text */
  border: none;
  border-radius: 50%; /* Round button */
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Collapsed State Icons */
.collapsed-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Space between icons */
  padding-top: 20px;
}

.collapsed-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Round avatars */
  cursor: pointer;
}

/* Sidebar Content Styles */
.sidebar-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto; /* Scrollable for long lists */
}

/* .messaging-sidebar-container::-webkit-scrollbar,
.profile-list::-webkit-scrollbar,
.expanded-sidebar::-webkit-scrollbar {
  display: none;
} */

/* Conversation List */
.conversation-list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between items */
}

.conversation-item {
  display: flex;
  align-items: center;
  /* padding: 0 10px; */
  border-radius: 8px; /* Rounded edges for items */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth hover effect */
  background-color: var(--chat); /* Dynamic background */
  color: var(--primary-text); /* Dynamic text */
}

.conversation-item:hover {
  background-color: var(--button-hover-bg); /* Dynamic hover effect */
}

.conversation-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Round avatars */
  margin-right: 10px; /* Space between avatar and text */
}

.conversation-name {
  font-weight: bold;
  color: var(--primary-text); /* Dynamic text */
}

.active {
  background-color: var(--chat); /* Highlight active item */
  color: var(--primary-text); /* Contrasting text */
}

/* Chat Window Styles */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Scrollable for long messages */
}

/* Chat Messages Container */
.chat-messages {
  flex: 3; /* Takes up 3/5 of the vertical space */
  background-color: var(--chat); /* Dynamic background */
  border-radius: 8px;
  overflow-y: auto; /* Scrollable for long messages */
  padding: 10px;
  margin-bottom: 10px; /* Space between messages and input */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inset shadow */
}

/* Individual Chat Message */
.chat-message {
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 10px;
  max-width: 100%; /* Limit width of messages */
  word-wrap: break-word;
}

/* Profile image */
.chat-message-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px; /* Space between image and content */
  align-self: flex-start; /* Align image with the top of the message */
}

/* Chat message container */
.chat-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

/* Message content */
.chat-message-content {
  max-width: 70%;
  display: flex;
  flex-direction: column;
}

/* Sender name and timestamp */
.chat-message-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-message-sender {
  font-weight: bold;
  color: var(--primary-text);
}

.chat-message-timestamp {
  font-size: 0.8em;
  color: var(--secondary-text);
}

/* Message text */
.chat-message-text {
  background-color: var(--chat);
  color: var(--primary-text);
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
}

/* Date divider */
.date-divider {
  text-align: center;
  margin: 10px 0;
  padding: 5px 0;
  border-bottom: 1px solid var(--button-hover-bg);
  font-size: 0.9em;
  color: var(--secondary-text);
}

/* Sent Messages */
.chat-sent {
  background-color: var(--chat); /* Light blue for sent messages */
  align-self: flex-start; /* Align sent messages to the left */
  text-align: left;
  border-bottom-right-radius: 0; /* Remove bottom-right rounding */
}

/* Received Messages */
.chat-received {
  background-color: var(--chat); /* Light gray for received messages */
  align-self: flex-start; /* Align received messages to the right */
  text-align: left;
  border-bottom-left-radius: 0; /* Remove bottom-left rounding */
}

/* Input Area */
.message-input-container {
  flex: 2; /* Takes up 2/5 of the vertical space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--chat);
  border-radius: 8px;
  padding: 10px;
  padding-bottom: 100px;
  box-shadow: inset 0 0.5px 0.5px rgba(0, 0, 0, 0.1);
}

.message-input {
  flex: 1; /* Ensures the input area scales properly */
  padding: 10px;
  font-size: 14px;
  outline: none;
  resize: none; /* Prevent resizing */
  margin-bottom: 10px;
}

/* Send Button */
.send-button {
  display: block;
  margin-top: 5px;
  padding: 10px 20px;
  background-color: var(--cta-bg);
  color: var(--cta-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.send-button:hover {
  background-color: var(--cta-bg-hover); /* Hover effect */
}

/* CSS for Close Button */
/* .chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
} */

.chat-header {
  display: flex;
  justify-content: space-between; /* Space between text and button */
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid var(--button-hover-bg); /* Subtle divider */
}

/* Chat Header Title */
.chat-header h4 {
  font-size: 1.15em;
  color: var(--primary-text); /* Dynamic text color */
  margin: 0;
}

/* Close Button: No background or border */
.close-button {
  background: none;
  border: none;
  font-size: 24px; /* Slightly larger for visibility */
  font-weight: bold;
  color: var(--primary-text); /* Dynamic text color */
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.3s ease; /* Smooth color change */
}

.close-button:hover {
  color: var(--cta-bg-hover); /* Red or custom hover effect */
}

.notification-badge {
  background-color: #6F58BE;
  color: white; /* White '!' */
  font-weight: bold;
  font-size: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
